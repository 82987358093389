<template>
  <div class="data_source" v-loading="loading" element-loading-background="rgba(24,34,45,0.9)">
    <section-title title="数据源"></section-title>
    <div class="data_source_create">
      <el-button type="primary" size="small" @click="onCreate">添加数据</el-button>
    </div>
    <div class="data_source_sort">
      <el-popover
        trigger="hover"
        placement="bottom-start"
        :visible-arrow="false"
        popper-class="my_popover">
        <p slot="reference" class="my_popover_reference text_size_14 text_color_white cursor-pointer">
          <span>{{searchType.label}}</span>
          <i class="el-icon-caret-bottom"></i>
        </p>
        <div class="my_popover_list">
          <div
            :class="['my_popover_list_item', { 'select': searchType.value === item.value }]"
            v-for="item in searchTypes"
            :key="item.value"
            @click="onSearchTypeChange(item)">
            <p>{{ item.label }}</p>
          </div>
        </div>
      </el-popover>
      <el-popover
        trigger="hover"
        placement="bottom-start"
        :visible-arrow="false"
        popper-class="my_popover">
        <p slot="reference" class="my_popover_reference text_size_14 text_color_white cursor-pointer">
          <span>{{orderType.label}}</span>
          <i class="el-icon-caret-bottom"></i>
        </p>
        <div class="my_popover_list">
          <div
            :class="['my_popover_list_item', { 'select': orderType.value === item.value }]"
            v-for="item in orderTypes"
            :key="item.value"
            @click="onOrderTypeChange(item)">
            <p>{{ item.label }}</p>
          </div>
        </div>
      </el-popover>
    </div>
    <div class="data_source_list">
      <vue-scroll>
        <div class="data_source_list_wrapper">
          <div class="data_item" v-for="item in dataList" :key="item.id">
            <div class="data_item_main" @click="onEdit(item)">
              <p class="data_item_main-type text_size_12">{{item.dataSourceType | dataSourceType}}</p>
              <p class="data_item_main-name text_size_16">{{item.title}}</p>
            </div>
            <i class="iconfont iconshanchu data_item_delete" @click="onDelete(item)"></i>
            <div class="data_item_footer text_size_12" @click="onEdit(item)">
              <p class="data_item_footer-creator">{{item.changedByUser}}</p>
              <p class="data_item_footer-date">{{item.updated_at}}</p>
            </div>
          </div>
        </div>
      </vue-scroll>
    </div>

    <el-dialog
      :title="dialogTitle"
      :visible="showDialog"
      width="600px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onClose">
      <el-form ref="form" :model="dataSource" :rules="rules" size="small"
        label-position="left" label-width="160px" class="margin-left-20 margin-right-20"
        hide-required-asterisk>
        <el-form-item label="类型">
          <el-select style="width:360px;" :disabled="dialogType === 'edit'" v-model="dataSource.dataSourceType" @change="onSourceTypeChange">
            <el-option value="1" label="数据库"></el-option>
            <el-option value="2" label="CSV文件"></el-option>
            <el-option value="3" label="API"></el-option>
          </el-select>
        </el-form-item>
        <!-- database -->
        <template v-if="dataSource.dataSourceType === '1'">
          <el-form-item label="数据源名称" prop="title">
            <el-input v-model="dataSource.title"></el-input>
          </el-form-item>
          <el-form-item label="IP" prop="formData.domain">
            <el-input v-model="dataSource.formData.domain"></el-input>
          </el-form-item>
          <el-form-item label="用户名" prop="formData.root">
            <el-input v-model="dataSource.formData.root"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="formData.password">
            <el-input type="password" v-model="dataSource.formData.password"></el-input>
          </el-form-item>
          <el-form-item label="端口" prop="formData.port">
            <el-input v-model="dataSource.formData.port"></el-input>
          </el-form-item>
          <el-form-item label="数据库名" prop="formData.database">
            <el-input v-model="dataSource.formData.database"></el-input>
          </el-form-item>
          <el-form-item label-width="0px">
            <p class="text_size_14 text_color_white margin-bottom-20">SQL工具箱</p>
            <el-checkbox class="margin-bottom-10" v-model="dataSource.formData.canCreateTable">允许使用SQL工具箱在该数据库中创建图表</el-checkbox>
            <el-checkbox v-model="dataSource.formData.onlyUseSelectSql">允许在 SQL工具箱中运行非 SELECT 语句</el-checkbox>
          </el-form-item>
        </template>
        <!-- database -->
        <!-- CSV -->
        <template v-if="dataSource.dataSourceType === '2'">
          <el-form-item label="数据源名称" prop="title">
            <el-input v-model="dataSource.title"></el-input>
          </el-form-item>
          <el-form-item label="CSV文件" prop="file" v-if="dialogType === 'create'">
            <upload-file v-model="dataSource.file"></upload-file>
          </el-form-item>
        </template>
        <!-- CSV -->
        <!-- api -->
        <template v-if="dataSource.dataSourceType === '3'">
          <el-form-item label="数据源名称" prop="title">
            <el-input v-model="dataSource.title"></el-input>
          </el-form-item>
          <el-form-item label="EndPoint" prop="formData.endPoint">
            <el-input v-model="dataSource.formData.endPoint"></el-input>
          </el-form-item>
          <el-form-item label="APIVersion">
            <el-input v-model="dataSource.formData.APIVersion"></el-input>
          </el-form-item>
          <el-form-item label="AppKey">
            <el-input v-model="dataSource.formData.AppKey"></el-input>
          </el-form-item>
          <el-form-item label="AppSecret">
            <el-input v-model="dataSource.formData.AppSecret"></el-input>
          </el-form-item>
          <el-form-item label="数据响应" prop="formData.timeGap">
            <div class="flex align_center text_size_14">
              <el-checkbox v-model="dataSource.formData.autoUpdated">数据自动更新</el-checkbox>
              <div class="flex" style="margin-left:52px;">
                <span class="margin-right-10">时间间隔</span>
                <el-select v-model="dataSource.formData.timeGap" style="width:130px;" :clearable="true">
                  <el-option label="1分钟" :value="1"></el-option>
                  <el-option label="5分钟" :value="5"></el-option>
                  <el-option label="10分钟" :value="10"></el-option>
                  <el-option label="30分钟" :value="30"></el-option>
                  <el-option label="1小时" :value="60"></el-option>
                  <el-option label="2小时" :value="120"></el-option>
                  <el-option label="12小时" :value="720"></el-option>
                  <el-option label="24小时" :value="24*60"></el-option>
                  <el-option label="1周" :value="7*24*60"></el-option>
                </el-select>
                <!-- <el-input style="width:50px;"></el-input>
                <span class="margin-left-10">秒</span> -->
              </div>
            </div>
          </el-form-item>
        </template>
        <!-- api -->
      </el-form>
      <div slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button type="primary" size="small" @click="onSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as api from '~/api'
import uploadFile from '~/components/upload-file/csv-upload'
export default {
  data () {
    const checkTimeGap = (rule, value, callback) => {
      if (this.dataSource.formData.autoUpdated) {
        if (value === '') {
          return callback(new Error('请选择时间间隔'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return {
      loading: false,
      searchType: {
        label: '全部',
        value: '0'
      },
      searchTypes: [
        { label: '全部', value: '0' },
        { label: '数据库', value: '1' },
        { label: 'CSV文件', value: '2' },
        { label: 'API', value: '3' }
      ], // 按照数据源类型搜索
      orderType: {
        label: '按修改时间排序',
        value: 'updated_at',
        orderBy: 'desc'
      },
      orderTypes: [
        { label: '按修改时间排序', value: 'updated_at', orderBy: 'desc' },
        { label: '按创建时间排序', value: 'created_at', orderBy: 'desc' },
        { label: '按名称排序', value: 'title', orderBy: 'asc' }
      ], // 按照条件排序
      dataList: [], // 数据源列表
      showDialog: false,
      dialogType: 'create',
      editItemId: '',
      dataSource: {
        dataSourceType: '',
        title: '',
        formData: {
          // 数据库
          domain: '',
          root: '',
          password: '',
          port: '',
          database: '',
          canCreateTable: false,
          onlyUseSelectSql: false,
          // api
          endPoint: '',
          APIVersion: '',
          AppKey: '',
          AppSecret: '',
          autoUpdated: false,
          timeGap: ''
        },
        // csv
        file: ''
      },
      rules: {
        dataSourceType: [
          { required: true, message: '请选择数据源类型', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请输入数据源名称', trigger: 'blur' }
        ],
        formData: {
          domain: [
            { required: true, message: '请输入数据库IP', trigger: 'blur' }
          ],
          root: [
            { required: true, message: '请输入数据库用户名', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入数据库密码', trigger: 'blur' }
          ],
          port: [
            { required: true, message: '请输入数据库端口', trigger: 'blur' }
          ],
          database: [
            { required: true, message: '请输入数据库名', trigger: 'blur' }
          ],
          endPoint: [
            { required: true, message: '请输入EndPoint', trigger: 'blur' }
          ],
          timeGap: [
            { validator: checkTimeGap, trigger: 'change' }
          ]
        },
        file: [
          { required: true, message: '请选择上传的CSV文件', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    projectId () {
      return this.$route.query.projectId
    },
    dialogTitle () {
      let title = ''
      switch (this.dialogType) {
        case 'create':
          title = '添加数据源'
          break
        case 'edit':
          title = '编辑数据'
          break
      }
      return title
    }
  },
  filters: {
    dataSourceType (value) {
      return {
        1: '数据库',
        2: 'CSV',
        3: 'API'
      }[value]
    }
  },
  components: {
    uploadFile
  },
  mounted () {
    this.getDataList()
  },
  methods: {
    // 选择不同的数据源类型
    onSearchTypeChange (item) {
      this.searchType = { ...item }
      this.getDataList()
    },
    // 选择不同的排序方式
    onOrderTypeChange (item) {
      this.orderType = { ...item }
      this.getDataList()
    },
    // 获取数据源列表
    getDataList () {
      this.loading = true
      api.getDataSourceList({
        spaceId: this.projectId,
        dataSourceType: this.searchType.value,
        orderWord: this.orderType.value,
        orderType: this.orderType.orderBy
      }).then(res => {
        this.loading = false
        if (res.data.code === 0) {
          this.dataList = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$message.error('网络错误！')
      })
    },
    // dialog关闭时清除表单
    onClose () {
      // this.$refs['form'].resetFields()
      this.$refs.form.clearValidate()
      this.clearData('0')
    },
    onSourceTypeChange (value) {
      this.$refs.form.clearValidate()
      this.clearData(value)
    },
    // 在切换数据源类型或者保存数据源时清理字段
    clearData (type) {
      switch (type) {
        case '0':
          this.dataSource = this.$clone(this.$options.data().dataSource)
          break
        case '1':
          // 选择数据库
          // 重置api特有字段
          this.dataSource.formData.endPoint = ''
          this.dataSource.formData.APIVersion = ''
          this.dataSource.formData.AppKey = ''
          this.dataSource.formData.autoUpdated = false
          this.dataSource.formData.timeGap = ''
          // 重置csv特有字段
          this.dataSource.file = ''
          break
        case '3':
          // 选择api
          // 重置数据库特有字段
          this.dataSource.formData.domain = ''
          this.dataSource.formData.root = ''
          this.dataSource.formData.password = ''
          this.dataSource.formData.port = ''
          this.dataSource.formData.database = ''
          this.dataSource.formData.canCreateTable = false
          this.dataSource.formData.onlyUseSelectSql = false
          // 重置csv特有字段
          this.dataSource.file = ''
          break
        case '2':
          this.dataSource.formData = { ...this.$options.data().dataSource.formData }
          break
      }
    },
    // 点击添加数据
    onCreate () {
      this.showDialog = true
      this.dialogType = 'create'
      this.dataSource.dataSourceType = '1'
    },
    // 创建数据源
    createConfirm () {
      const data = {
        spaceId: this.projectId,
        ...this.dataSource
      }
      if (this.dataSource.dataSourceType === '2') {
        const formData = new FormData()
        Object.keys(data).forEach((child) => {
          if (child !== 'formData') {
            formData.append(child, data[child])
          }
        })
        api.createDataSourceCSV(formData).then(res => {
          if (res.data.code === 0) {
            this.$message.success('数据源添加成功')
            this.showDialog = false
            this.getDataList()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('网络错误')
        })
      } else {
        api.createDataSource(data).then(res => {
          if (res.data.code === 0) {
            this.$message.success('数据源添加成功')
            this.showDialog = false
            this.getDataList()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('网络错误')
        })
      }
    },
    // 点击编辑数据源
    onEdit (item) {
      this.showDialog = true
      this.dialogType = 'edit'
      // 表单赋值
      this.editItemId = item.id
      this.dataSource.title = item.title
      this.dataSource.dataSourceType = item.dataSourceType
      if (item.dataSourceType !== '2') {
        this.dataSource.formData = { ...item.formData }
      }
    },
    // 保存数据源编辑
    editConfirm () {
      api.editDataSource({
        spaceId: this.projectId,
        dataSourceId: this.editItemId,
        ...this.dataSource
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('数据源编辑成功')
          this.showDialog = false
          this.getDataList()
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('网络错误')
      })
    },
    // 提交时表单验证
    onSubmit () {
      console.log('123123')
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.clearData(this.dataSource.dataSourceType)
          if (this.dialogType === 'create') {
            this.createConfirm()
          } else if (this.dialogType === 'edit') {
            this.editConfirm()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    onDelete (item) {
      this.$confirm('确定要删除该数据源吗？删除后将无法恢复', '删除数据源', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.deleteDataSource({
          dataSourceId: item.id
        }).then(res => {
          if (res.data.code === 0) {
            this.$message.success('该数据源已删除')
            this.getDataList()
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('网络错误！')
        })
      }).catch(() => {
        this.$message.info('操作已取消')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.data_source {
  flex: 1;
  padding: 30px 40px 20px 40px;
  display: flex;
  flex-direction: column;
  &_create {
    margin: 20px 0;
    display: flex;
    align-items: center;
  }
  &_sort {
    margin: 6px 0;
    display: flex;
    align-items: center;
    .my_popover_reference {
      margin-right: 38px;
      & > i {
        margin-left: 8px;
      }
    }
  }
  &_list {
    min-width: 300px;
    margin: -10px;
    padding: 10px 0;
    flex: 1;
    &_wrapper {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.data_item {
  position: relative;
  flex: none;
  width: 270px;
  height: 160px;
  color: $color-white;
  margin: 10px;
  border-radius: 4px;
  border: 1px solid $black-color-light-6;
  background-image: url('../../../../assets/img/list_item_bg.png');
  background-size: cover;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  user-select: none;
  &:hover {
    cursor: pointer;
    .data_item_main {
      background-color: rgba($color: $black-color-light-3, $alpha: .8);
      &-name {
        color: #6AEBFF;
      }
    }
    .data_item_delete {
      display: block;
    }
    .data_item_footer {
      background-color: rgba($color: $primary-black-color, $alpha: .8);
    }
  }
  &_delete {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    color: $black-color-light-10;
    &:hover {
      cursor: pointer;
      color: $color-primary;
    }
  }
  &_main {
    flex: 1;
    display: flex;
    flex-direction: column;
    &-type {
      position: relative;
      height: 30px;
      color: $color-primary;
      padding: 0 10px 0 20px;
      display: flex;
      align-items: center;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $color-primary;
      }
    }
    &-name {
      flex: 1;
      width: 268px;
      padding: 0 20px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      word-break: break-all;
    }
  }
  &_footer {
    height: 40px;
    color: $color-text-secondary;
    padding: 0 20px;
    background-color: rgba($color: $primary-black-color, $alpha: .5);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
