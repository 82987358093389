<!-- 上传组件的封装 -->
<template>
  <el-upload
    ref="uploadFiles"
    action=""
    :multiple="false"
    :limit="1"
    accept="text/csv"
    :auto-upload="false"
    :on-remove="removeFile"
    :on-change="onChange"
    :on-error="errorUpload"
    :on-exceed="handleExceed"
    :before-upload="beforeUpload">
    <el-button size="small">点击上传</el-button>
    <!-- <p slot="tip" class="upload_tips text-12-R text_color_secondary">上传CSV文件</p> -->
  </el-upload>
</template>
<script>
export default {
  props: {
    value: {

    }
  },
  methods: {
    // 上传成功
    onChange (file, fileList) {
      console.log('change', file, fileList)
      this.$emit('input', file.raw)
    },
    // 文件上传之前
    beforeUpload (file) {
      if (this.$refs.uploadFiles.accept.search(file.type) === -1 || file.type === '') {
        this.$message.warning('文件格式不正确！')
        return false
      }
      if (file.size / 1024 / 1024 > 5) {
        this.$message.warning('文件大小不得超过5M')
        return false
      }
    },
    // 上传文件出错
    errorUpload (error, file, fileList) {
      console.log(error)
      this.$message.error('文件上传出错')
    },
    // 删除文件
    removeFile (file, fileList) {
      console.log('remove', file, fileList)
      this.$emit('input', '')
    },
    // 文件超出个数限制
    handleExceed (files, fileList) {
      this.$message.warning('超出文件数量限制')
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
